.app-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.content-wrapper {
	display: flex;
	flex: 1;
	min-height: 95vh;
}

.main-content {
	flex: 1;
	margin: 50px 0px 0px 0px;
}

#navbarNav {
	position: absolute;
	right: 10px;
	top: 60px;
	background: #fff;
	padding: 20px 30px;
	box-shadow: 0px 0px 25px rgba(80, 80, 80, 0.1);
	border-radius: 10px;
}

footer {
	margin-top: auto;
}
